export default (theme: string) => {
  switch (theme) {
    case "Default":
      return "*:first:!pl-0";
    case "White":
      return "bg-white";
    case "Blue":
      return "bg-blue-50";
    case "Rust":
      return "bg-rust-75";
    case "Yellow":
      return "bg-yellow-75";
    case "Green":
      return "bg-green-50";
    default:
      return "";
  }
};
